exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-movie-jsx": () => import("./../../../src/pages/movie.jsx" /* webpackChunkName: "component---src-pages-movie-jsx" */),
  "component---src-pages-news-jsx": () => import("./../../../src/pages/news.jsx" /* webpackChunkName: "component---src-pages-news-jsx" */),
  "component---src-pages-staffcast-jsx": () => import("./../../../src/pages/staffcast.jsx" /* webpackChunkName: "component---src-pages-staffcast-jsx" */),
  "component---src-templates-news-detail-page-jsx": () => import("./../../../src/templates/NewsDetailPage.jsx" /* webpackChunkName: "component---src-templates-news-detail-page-jsx" */),
  "component---src-templates-news-page-jsx": () => import("./../../../src/templates/NewsPage.jsx" /* webpackChunkName: "component---src-templates-news-page-jsx" */)
}

