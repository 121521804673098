import "./src/styles/reset.css";
import "./src/styles/style.scss";
import "aos/dist/aos.css";
import AOS from "aos";

export const onClientEntry = () => {
	AOS.init({
		offset: 150,
		delay: 300,
		duration: 1200,
		easing: "ease-out",
		once: true,
	});
};

// ページ遷移時にAOSをリフレッシュして新しい要素にアニメーションを適用
export const onRouteUpdate = () => {
	AOS.refresh();
};
